import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'BOW-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0xBb8502132C87Ee31bE0E2bC1CB8CC69374488261',
    },
    token: tokens.bow,
    quoteToken: tokens.wcore,
  },
  {
    pid: 9,
    lpSymbol: 'BOW-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0x26601E75074B385594Ea5552F7B1EE5Ad7cF0403',
    },
    token: tokens.bow,
    quoteToken: tokens.usdt,
  },
  {
    pid: 3,
    lpSymbol: 'HUNT-CORE LP',
    lpAddresses: {
      1115: '',
      1116: '0x798489bacDDf8A3c5B1C23E7c24833B349F41dED',
    },
    token: tokens.wcore,
    quoteToken: tokens.hunt,
  },
  {
    pid: 4,
    lpSymbol: 'WCORE-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0xd24f5b6fA3064022A07c0D5dBE875a0e1AFB4354',
    },
    token: tokens.wcore,
    quoteToken: tokens.usdt,
  },
  {
    pid: 14,
    lpSymbol: 'USDC-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0xf07c9727c9f14B0BC22d0F1D450Fe3a87c050C46',
    },
    token: tokens.usdc,
    quoteToken: tokens.wcore,
  },
  {
    pid: 5,
    lpSymbol: 'WCORE-WETH LP',
    lpAddresses: {
      1115: '',
      1116: '0x48e0619aF7d6D58A3c01ABB33c94ceFc1d5809a2',
    },
    token: tokens.wcore,
    quoteToken: tokens.eth,
  },
  {
    pid: 6,
    lpSymbol: 'WETH-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0xf801c524bbC27DDB62190E1d8504640175ef5e18',
    },
    token: tokens.eth,
    quoteToken: tokens.usdt,
  },
  {
    pid: 7,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      1115: '',
      1116: '0x859F8fF7882Af9b6De0d15077b2725E25806c5f3',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
  },
  {
    pid: 10,
    lpSymbol: '4TOKEN-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0x5Ab9f0Ea4fD182a1edC89D379c1F1c5d6B6eF623',
    },
    token: tokens.fourToken,
    quoteToken: tokens.wcore,
  },
  {
    pid: 11,
    lpSymbol: 'ACORE-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0x1B55BE6b76Eba3c1af8CFfd933bC61BCB2dc4a66',
    },
    token: tokens.acore,
    quoteToken: tokens.wcore,
  },
  {
    pid: 12,
    lpSymbol: 'POON-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0x0B19591D1ADD665C5CB0A711089a1181708F45EC',
    },
    token: tokens.poon,
    quoteToken: tokens.wcore,
  },
  {
    pid: 13,
    lpSymbol: 'H4TOKEN-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0xee298b8b6EC12A265889BE4409a6BECdFE200e66',
    },
    token: tokens.h4token,
    quoteToken: tokens.wcore,
  },
  {
    pid: 15,
    lpSymbol: 'VITA-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0x513bf72eE8D9034953C766b2CC61E55581409B9A',
    },
    token: tokens.vita,
    quoteToken: tokens.wcore,
  },
  {
    pid: 16,
    lpSymbol: 'BTC.b-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0xda5673BcE138E0aD8A506C64627150f81D156f34',
    },
    token: tokens.btc,
    quoteToken: tokens.usdt,
  },
  {
    pid: 17,
    lpSymbol: 'cDAO-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0x4B24E7216e0c71C8Aa964687a54258Ab0d8a4500',
    },
    token: tokens.cdao,
    quoteToken: tokens.usdt,
  },
]

export default farms
