import React from 'react'
import styled from 'styled-components'
import { Text, Flex, CloseIcon, IconButton } from 'archerswap-uikit'
import Box from '@mui/material/Box'
import { useTranslation } from 'contexts/Localization'
import { BASE_URL, BACK_DOMAIN } from 'config'
import usePhishingBanner from 'hooks/usePhishingBanner'
import useTheme from 'hooks/useTheme'

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  padding: 12px;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0px;
  }
`

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const SpeechBubble = styled.div`
  /* background: rgba(39, 38, 44, 0.4); */
  background: ${(props) => (props.theme.isDark ? 'rgba(39, 38, 44, 0.4)' : '#ffffff')};
  border-radius: 16px;
  padding: 8px;
  width: 60%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & ${Text} {
    flex-shrink: 0;
    margin-right: 4px;
  }
`

const PhishingWarningBanner: React.FC = () => {
  const { t } = useTranslation()
  const [, hideBanner] = usePhishingBanner()
  const { isDark } = useTheme()

  const warningTextComponent = (
    <>
      <Text as="span" color="warning" small bold textTransform="uppercase">
        {t('Phishing warning: ')}
      </Text>
      <Text small as="span" color={`${isDark ? '#BDC2C4' : '#000000'}`} marginRight={1}>
        please make sure you&apos;re visiting
      </Text>
      <Text small as="span" bold color={`${isDark ? '#FFFFFF' : '#57534e'}`}>
        {BASE_URL}
      </Text>
      <br />
      <Text small as="span" color={`${isDark ? '#BDC2C4' : '#000000'}`} marginRight={1}>
        (backup url
      </Text>
      <Text small as="span" bold color={`${isDark ? '#FFFFFF' : '#57534e'}`} style={{ marginRight: '0px' }}>
        {BACK_DOMAIN}
      </Text>
      <Text small as="span" color={`${isDark ? '#BDC2C4' : '#000000'}`}>
        )
      </Text>
    </>
  )

  return (
    <Container className="warning-banner">
      <style>{`
        .warning-banner {
          background: ${
            isDark
              ? 'linear-gradient(180deg, #0f0f0f 0%, #1c1917 100%)'
              : 'linear-gradient(180deg, #ffffff 0%, #f8f4f9 100%)'
          };
        }
      `}</style>
      <Box display={{ xs: 'flex', lg: 'none' }} width="100%" height="100%">
        <Box>{warningTextComponent}</Box>
        <IconButton onClick={hideBanner} variant="text">
          <CloseIcon color={`${isDark ? '#FFFFFF' : '#000000'}`} />
        </IconButton>
      </Box>
      <Box display={{ xs: 'none', lg: 'flex' }} alignItems="center" width="100%" height="100%">
        <InnerContainer>
          {/* <img src="/images/decorations/phishing-warning-bunny.png" alt="phishing-warning" width="78px" /> */}
          <SpeechBubble>{warningTextComponent}</SpeechBubble>
        </InnerContainer>
        <IconButton onClick={hideBanner} variant="text">
          <CloseIcon color={`${isDark ? '#FFFFFF' : '#000000'}`} />
        </IconButton>
      </Box>
    </Container>
  )
}

export default PhishingWarningBanner
