import React, { useState } from 'react'

const TokenCreationContext = React.createContext({
  tokenAddr: undefined,
  setTokenAddr: (addr) => addr,
  isVerified: false,
  setIsVerified: (val) => val,
})

// This context maintain 2 counters that can be used as a dependencies on other hooks to force a periodic refresh
const TokenCreationContextProvider = ({ children }) => {
  const [tokenAddr, setTokenAddr] = useState<undefined | string>('123123')
  const [isVerified, setIsVerified] = useState(false)

  return (
    <TokenCreationContext.Provider value={{ tokenAddr, setTokenAddr, isVerified, setIsVerified }}>
      {children}
    </TokenCreationContext.Provider>
  )
}

export { TokenCreationContext, TokenCreationContextProvider }
