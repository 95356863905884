import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.xbow,
    earningToken: tokens.bow,
    contractAddress: {
      1115: '',
      1116: '0xdbf74f167a4e0b97a072c7ed51df6c6e8ec0353b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: tokens.xbow,
    earningToken: tokens.hunt,
    contractAddress: {
      1115: '',
      1116: '0xCDbfc330f64b122205F29e9394233cD1ebffbCa0',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.002893',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 2,
    stakingToken: tokens.xbow,
    earningToken: tokens.wcore,
    contractAddress: {
      1115: '',
      1116: '0x87bB2fE70b7129AA168FF7e6679e424eEF4261F0',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.000964506',
    sortOrder: 2,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 8,
    stakingToken: tokens.hunt,
    earningToken: tokens.bow,
    contractAddress: {
      1115: '',
      1116: '0xdbf74f167a4e0b97a072c7ed51df6c6e8ec0353b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerSecond: '0',
    sortOrder: 3,
    isFinished: false,
  },
  {
    sousId: 3,
    stakingToken: tokens.xbow,
    earningToken: tokens.fourToken,
    contractAddress: {
      1115: '',
      1116: '0x05fB00a7F695473E166616C3c3Fc472dda95f2DA',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '51.440329',
    sortOrder: 4,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 4,
    stakingToken: tokens.xbow,
    earningToken: tokens.acore,
    contractAddress: {
      1115: '',
      1116: '0xd8D62d018eb1727F63FaDD2e2bEa5b8B595B3346',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.011574',
    sortOrder: 5,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 5,
    stakingToken: tokens.xbow,
    earningToken: tokens.wcore,
    contractAddress: {
      1115: '',
      1116: '0x153CDa41B8dCE725978e2466ba8e69D6f38dE5eb',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.001157407',
    sortOrder: 6,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 6,
    stakingToken: tokens.xbow,
    earningToken: tokens.acore,
    contractAddress: {
      1115: '',
      1116: '0x0FE3596A8d861eb79367224448259B78D86a9B80',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.009093915',
    sortOrder: 7,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 7,
    stakingToken: tokens.xbow,
    earningToken: tokens.wcore,
    contractAddress: {
      1115: '',
      1116: '0xC9652e6B56C7f42869CC675Fa4E4b8A6eEB3BA28',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.001350308',
    sortOrder: 8,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 9,
    stakingToken: tokens.hunt,
    earningToken: tokens.wcore,
    contractAddress: {
      1115: '',
      1116: '0x0bAea43a9b5e843D4E7114c8E147A4cd287e5E60',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.0005787037037',
    sortOrder: 9,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 10,
    stakingToken: tokens.xbow,
    earningToken: tokens.h4token,
    contractAddress: {
      1115: '',
      1116: '0x58B5B6b6BFce36E417B79e5F152F0ebBc3E359bB',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.1929012345679',
    sortOrder: 10,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 11,
    stakingToken: tokens.xbow,
    earningToken: tokens.hunt,
    contractAddress: {
      1115: '',
      1116: '0xDa327355fE134AFf952663711D6E4b07dD65A1Db',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.002893',
    sortOrder: 11,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 12,
    stakingToken: tokens.xbow,
    earningToken: tokens.wcore,
    contractAddress: {
      1115: '',
      1116: '0xDb122A18E4F203f219956d4E945b81139a67501E',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '0.001286',
    sortOrder: 12,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 13,
    stakingToken: tokens.xbow,
    earningToken: tokens.vita,
    contractAddress: {
      1115: '',
      1116: '0xa23142748E7Fcd1e35447F1df28947a187D595a6',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerSecond: '64.3004115',
    sortOrder: 13,
    isFinished: false,
    depositFee: 0,
  },
]

export default pools
