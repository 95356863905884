import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'vita',
    address: '0xAeBF244F7e44035D7Daba70a90b960CE705ea81F',
    isActive: true,
    isPrivate: false,
    name: 'Vitamin Core',
    description: 'The Reflective Gaming Token on Core Chain. A Reflective Reward Token bringing GameFi to Corechain',
    launchDate: 'Oct. 31',
    launchTime: '12 PM GMT',
    saleAmount: '4,000,000,000 VITA',
    raiseAmount: '$10,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://vitamincore.financial/',
    currency: 'BOW',
    currencyAddress: '0x1a639e150d2210a4be4a5f0857a9151b241e7ae4',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'VITA',
    releaseTime: 1698659184,
    campaignId: '511080000',
    maxDepositAmount: 8000,
  },
  {
    id: 'ars',
    address: '0xB0c39405632CeDad542980B1E8f832D0857F32AF',
    isActive: false,
    isPrivate: false,
    name: 'The First Money Market on CORE Chain',
    description:
      'Money Markets. A one stop shop DeFi platform for lenders and borrowers using borderless stablecoins for fast and affordable transactions on CORE Chain',
    launchDate: 'Apr. 24',
    launchTime: '5 PM GMT',
    saleAmount: '24,750,000 ARS',
    raiseAmount: '$247,500',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://aquarius.loan',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'ARS',
    releaseTime: 1682238970,
    campaignId: '511080000',
    maxDepositAmount: 1000,
    vestingPercentage: 50,
  },
  {
    id: 'ars',
    address: '0x4092A660096e25Dc9dd692389D93584856B6e6B8',
    isActive: false,
    isPrivate: true,
    name: 'The First Money Market on CORE Chain',
    description:
      'Money Markets. A one stop shop DeFi platform for lenders and borrowers using borderless stablecoins for fast and affordable transactions on CORE Chain',
    launchDate: 'Apr. 24',
    launchTime: '17 PM GMT',
    saleAmount: '12,500,000 ARS',
    raiseAmount: '$125,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://aquarius.loan',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'ARS',
    releaseTime: 1682238970,
    campaignId: '511080000',
    maxDepositAmount: 500,
    vestingPercentage: 50,
  },
  {
    id: 'poon',
    address: '0xfB5c4450A50A6Ef3a0f7ef74C4bbe7D1015Ab110',
    isActive: false,
    isPrivate: false,
    name: 'The Trading and Liquidity Marketplace on Core Chain',
    description: 'A next gen liquidity layer for projects to build upon + improved ve(3,3) dex on Core Chain',
    launchDate: 'Apr. 17',
    launchTime: '17 PM GMT',
    saleAmount: '3,232,000 POON',
    raiseAmount: '$404,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://spoon.exchange',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'POON',
    releaseTime: 1681488007,
    campaignId: '511080000',
    maxDepositAmount: 2000,
  },
  {
    id: 'poon',
    address: '0x618faBEB2C7d3e8232b27Dda7F87D1124EDD48AD',
    isActive: false,
    isPrivate: true,
    name: 'The Trading and Liquidity Marketplace on Core Chain',
    description: 'A next gen liquidity layer for projects to build upon + improved ve(3,3) dex on Core Chain',
    launchDate: 'Apr. 17',
    launchTime: '12 PM GMT',
    saleAmount: '2,400,000 POON',
    raiseAmount: '$300,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://spoon.exchange',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'POON',
    releaseTime: 1681488007,
    campaignId: '511080000',
    maxDepositAmount: 1000,
  },
  {
    id: 'autocore',
    address: '0x823445F03D64EbE43cb12a6979f4B7C4d5Da8910',
    isActive: false,
    isPrivate: false,
    name: 'The First DeFi Yield Maximizer on Core Chain',
    description:
      'Auto Core is a DeFi Yield Maximizer or Optimizer that allows its users to make more crypto with crypto while keeping the process simple and automated with its auto-compounding vaults. Auto Core has its multiutility token $ACORE at its core, Auto Core is also a DAO and it lets its community decide through voting on how they want generated platform revenue to be distributed among $ACORE holders.',
    launchDate: 'Apr. 12',
    launchTime: '17 PM GMT',
    saleAmount: '251,200 ACORE',
    raiseAmount: '$62,800',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://www.autocore.finance/#/core',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'ACORE',
    releaseTime: 1681164000,
    campaignId: '511080000',
    maxDepositAmount: 100,
  },
  {
    id: 'autocore',
    address: '0x4D5B6D4eb3351F09fC177a7B7958D5381D7aFE06',
    isActive: false,
    isPrivate: true,
    name: 'The First DeFi Yield Maximizer on Core Chain',
    description:
      'Auto Core is a DeFi Yield Maximizer or Optimizer that allows its users to make more crypto with crypto while keeping the process simple and automated with its auto-compounding vaults. Auto Core has its multiutility token $ACORE at its core, Auto Core is also a DAO and it lets its community decide through voting on how they want generated platform revenue to be distributed among $ACORE holders.',
    launchDate: 'Apr. 12',
    launchTime: '12 PM GMT',
    saleAmount: '240,000 ACORE',
    raiseAmount: '$60,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://www.autocore.finance/#/core',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'ACORE',
    releaseTime: 1681164000,
    campaignId: '511080000',
    maxDepositAmount: 150,
  },
  {
    id: 'miidas',
    address: '0x6438c1ed87d921e152441A2aeC3C6b0986d746aA',
    isActive: false,
    isPrivate: false,
    name: "The world's multichain NFT marketplace, launchpad and staking pool for both digital and physical assets.",
    description:
      "Miidas is the world's first and fastest growing NFT Marketplace for both digital and physical assets. It is also the largest NFT platforms in Malaysia, providing an all-in-one multichain platform including a marketplace, launchpad and staking pool for the creators and investors of both physical and digital assets. Miidas NFT is a project verified by CoreDAO, the official decentralized organization developing the Satoshi Plus ecosystem. Miidas currently has over 40k members, 35k NFTs and $900,000.00 volume traded since May 2022.",
    launchDate: 'Mar. 28',
    launchTime: '1 AM GMT',
    saleAmount: '200,000,000 Miidas',
    raiseAmount: '$30,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://core.miidas.com',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 6,
    tokenSymbol: 'Miidas',
    releaseTime: 1679961600,
    campaignId: '511080000',
    maxDepositAmount: 100,
  },
  {
    id: 'miidas',
    address: '0xE91A4D72b032a302EBa0C9a9Ea5f560833290Ade',
    isActive: false,
    isPrivate: true,
    name: "The world's multichain NFT marketplace, launchpad and staking pool for both digital and physical assets.",
    description:
      "Miidas is the world's first and fastest growing NFT Marketplace for both digital and physical assets. It is also the largest NFT platforms in Malaysia, providing an all-in-one multichain platform including a marketplace, launchpad and staking pool for the creators and investors of both physical and digital assets. Miidas NFT is a project verified by CoreDAO, the official decentralized organization developing the Satoshi Plus ecosystem. Miidas currently has over 40k members, 35k NFTs and $900,000.00 volume traded since May 2022.",
    launchDate: 'Mar. 27',
    launchTime: '12 PM GMT',
    saleAmount: '280,000,000 Miidas',
    raiseAmount: '$40,040',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://core.miidas.com',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 6,
    tokenSymbol: 'Miidas',
    releaseTime: 1679875778,
    campaignId: '511080000',
    maxDepositAmount: 150,
  },
  {
    id: 'hunter',
    address: '0x082D048107BE772Ec8e38502169fC8C2316D8320',
    isActive: false,
    isPrivate: false,
    name: '$HUNT is a next-generation Core reward token on the Core Chain ecosystem.',
    description:
      '$HUNT is a next-generation CORE reward token on the Core Chain ecosystem. 2% of every transaction made with the $HUNT tokens, goes back to holders of $HUNT in Core Chain rewards.',
    launchDate: 'Mar. 20',
    launchTime: '3 PM GMT',
    saleAmount: '300,000 HUNT',
    raiseAmount: '$120,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://archerswap.finance',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'HUNT',
    releaseTime: 1678916830,
    campaignId: '511080000',
    maxDepositAmount: 2500,
  },
  {
    id: 'hunter',
    address: '0x7dBE86C6D0066Ff15FFBCF2B655B1aD977354686',
    isActive: false,
    isPrivate: true,
    name: '$HUNT is a next-generation Core reward token on the Core Chain ecosystem.',
    description:
      '$HUNT is a next-generation CORE reward token on the Core Chain ecosystem. 2% of every transaction made with the $HUNT tokens, goes back to holders of $HUNT in Core Chain rewards.',
    launchDate: 'Mar. 20',
    launchTime: '12 PM GMT',
    saleAmount: '200,000 HUNT',
    raiseAmount: '$72,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://archerswap.finance',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'HUNT',
    releaseTime: 1678916830,
    campaignId: '511080000',
    maxDepositAmount: 1500,
  },
  {
    id: 'bow',
    address: '0x745B5b86493220E61CdEDa9FA1dC0Bf45a974BCE',
    isActive: false,
    isPrivate: false,
    name: 'The Best AMM+NFT DEX on Core Chain',
    description:
      "ArcherSwap is a crypto world for users to trade, earn, and game. It's the best choice for projects on Core Chain with features including AMM, NFT, and GameFi.",
    launchDate: 'Mar. 13',
    launchTime: '3 PM GMT',
    saleAmount: '400,000 BOW',
    raiseAmount: '$100,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://archerswap.finance',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BOW',
    releaseTime: 1678806000,
    campaignId: '511080000',
    maxDepositAmount: 2500,
  },
  {
    id: 'bow',
    address: '0xb5e184BF5cE631fE8B428dA866C0f4D4124Ad398',
    isActive: false,
    isPrivate: true,
    name: 'The Best AMM+NFT DEX on Core Chain',
    description:
      "ArcherSwap is a crypto world for users to trade, earn, and game. It's the best choice for projects on Core Chain with features including AMM, NFT, and GameFi.",
    launchDate: 'Mar. 13',
    launchTime: '12 PM GMT',
    saleAmount: '200,000 BOW',
    raiseAmount: '$50,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://archerswap.finance',
    currency: 'WCORE',
    currencyAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BOW',
    releaseTime: 1678716000,
    campaignId: '511080000',
    maxDepositAmount: 2500,
  },
]

export default ifos
