import { ChainId } from 'archerswap-sdk'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Connector } from '@web3-react/types'
// eslint-disable-next-line import/no-unresolved
// import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useMemo, useCallback } from 'react'
// import { injected } from '../connectors'
import {
  ConnectionType,
  arkaneConnection,
  bitgetConnection,
  blockWalletConnection,
  braveWalletConnection,
  // coinbaseWalletConnection,
  cypherDConnection,
  getConnections,
  // gnosisSafeConnection,
  metamaskConnection,
  networkConnection,
  okxWalletConnection,
  phantomConnection,
  trustWalletConnection,
  walletConnectConnection,
  getNetworkLibrary,
} from 'connectors'
// import { NetworkContextName } from '../constants'

export function useActiveWeb3React() {
  const context = useWeb3ReactCore()

  const chainId: ChainId | undefined = useMemo(() => {
    if (!context.chainId || ![1116].includes(context.chainId)) {
      return ChainId.MAINNET
    }
    return context.chainId
  }, [context.chainId])

  return {
    ...context,
    chainId,
    currentChainId: context.chainId,
    library: context.provider ?? getNetworkLibrary(),
  }
}

export function useGetConnection() {
  return useCallback((c: Connector | ConnectionType) => {
    if (c instanceof Connector) {
      const connection = getConnections().find((conn) => conn.connector === c)
      if (!connection) {
        throw Error('unsupported connector')
      }
      return connection
    }

    switch (c) {
      case ConnectionType.METAMASK:
        return metamaskConnection
      // case ConnectionType.COINBASE_WALLET:
      //   return coinbaseWalletConnection;
      case ConnectionType.WALLET_CONNECT:
        return walletConnectConnection
      case ConnectionType.NETWORK:
        return networkConnection
      // case ConnectionType.GNOSIS_SAFE:
      //   return gnosisSafeConnection;
      case ConnectionType.ARKANE:
        return arkaneConnection
      case ConnectionType.PHATOM:
        return phantomConnection
      case ConnectionType.TRUSTWALLET:
        return trustWalletConnection
      case ConnectionType.BITGET:
        return bitgetConnection
      case ConnectionType.BLOCKWALLET:
        return blockWalletConnection
      case ConnectionType.BRAVEWALLET:
        return braveWalletConnection
      case ConnectionType.CYPHERD:
        return cypherDConnection
      case ConnectionType.OKXWALLET:
        return okxWalletConnection
      default:
        throw Error('unsupported connector')
    }
  }, [])
}
