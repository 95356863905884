import tokens from './tokens'
import { VaultConfig } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 1,
    lpSymbol: 'BOW-WCORE LP',
    lpAddresses: {
      1115: '',
      1116: '0xBb8502132C87Ee31bE0E2bC1CB8CC69374488261',
    },
    vaultAddresses: {
      1115: '',
      1116: '0x456F9706dCDC5448F181dd008360f1fd879f0d7b',
    },
    strategyAddresses: {
      1115: '',
      1116: '0x0bfe632a3948c4f9b44fF46b80966e34e890997E',
    },
    token: tokens.bow,
    quoteToken: tokens.wcore,
    provider: 'Archerswap',
  },
  {
    pid: 4,
    lpSymbol: 'WCORE-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0xd24f5b6fA3064022A07c0D5dBE875a0e1AFB4354',
    },
    vaultAddresses: {
      1115: '',
      1116: '0xEed5E6f78e5CdD986967Af4c86B2Bc63eB0C9f65',
    },
    strategyAddresses: {
      1115: '',
      1116: '0xCc528d0100C94e948F97ce31378B1Cc909D4f753',
    },
    token: tokens.wcore,
    quoteToken: tokens.usdt,
    provider: 'Archerswap',
  },
  {
    pid: 5,
    lpSymbol: 'WCORE-WETH LP',
    lpAddresses: {
      1115: '',
      1116: '0x48e0619aF7d6D58A3c01ABB33c94ceFc1d5809a2',
    },
    vaultAddresses: {
      1115: '',
      1116: '0x983A47574B22728c7622640e3845a43760CdE246',
    },
    strategyAddresses: {
      1115: '',
      1116: '0x1b7BF7624B9834433Ca296bF73E55Fdf4E1901C8',
    },
    token: tokens.wcore,
    quoteToken: tokens.eth,
    provider: 'Archerswap',
  },
  {
    pid: 9,
    lpSymbol: 'BOW-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0x26601E75074B385594Ea5552F7B1EE5Ad7cF0403',
    },
    vaultAddresses: {
      1115: '',
      1116: '0x17E801Ec80316Fc11547F0548510a72B66178C65',
    },
    strategyAddresses: {
      1115: '',
      1116: '0x1b239df77fc95F5Dece2C16a8D9208b72879144b',
    },
    token: tokens.bow,
    quoteToken: tokens.usdt,
    provider: 'Archerswap',
  },
  {
    pid: 14,
    lpSymbol: 'WCORE-USDC LP',
    lpAddresses: {
      1115: '',
      1116: '0xf07c9727c9f14B0BC22d0F1D450Fe3a87c050C46',
    },
    vaultAddresses: {
      1115: '',
      1116: '0xf80f92CA0B37892b327BAA86378D40bDE9a0Dd62',
    },
    strategyAddresses: {
      1115: '',
      1116: '0xA2a0fE8DcfD0a5A9c5E08fdE22583D1185Aa59D4',
    },
    token: tokens.wcore,
    quoteToken: tokens.usdc,
    provider: 'Archerswap',
  },
  {
    pid: 6,
    lpSymbol: 'WETH-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0xf801c524bbC27DDB62190E1d8504640175ef5e18',
    },
    vaultAddresses: {
      1115: '',
      1116: '0x0eeF4fe387Bb758a3fc160F460876B7bA16CcEe7',
    },
    strategyAddresses: {
      1115: '',
      1116: '0xe62546670A9e2f116eCF502E7a1042C15504489D',
    },
    token: tokens.eth,
    quoteToken: tokens.usdt,
    provider: 'Archerswap',
  },
  {
    pid: 7,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      1115: '',
      1116: '0x859F8fF7882Af9b6De0d15077b2725E25806c5f3',
    },
    vaultAddresses: {
      1115: '',
      1116: '0xcdaaFa009d795dB20d36839C614a243D26a8F1f8',
    },
    strategyAddresses: {
      1115: '',
      1116: '0xb82D5E443a2c18d8eEd9aEA709d31fD9862da80c',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
    provider: 'Archerswap',
  },
  {
    pid: 17,
    lpSymbol: 'cDAO-USDT LP',
    lpAddresses: {
      1115: '',
      1116: '0x4B24E7216e0c71C8Aa964687a54258Ab0d8a4500',
    },
    vaultAddresses: {
      1115: '',
      1116: '0x5dcec2311b5F29A1e4404D79dd57E87E6AD19e4A',
    },
    strategyAddresses: {
      1115: '',
      1116: '0xf075768f7Ea9ccF1601BC9F2d8793b4cC79b9B25',
    },
    token: tokens.cdao,
    quoteToken: tokens.usdt,
    provider: 'Archerswap',
  },
]

export default vaults
