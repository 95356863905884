import styled from 'styled-components'
import Container from './Container'

const Page = styled(Container)<any>`
  min-height: calc(100vh - 64px);
  padding-top: ${({ isHome }) => (isHome ? '0px' : '16px')};
  padding-bottom: 16px;
  max-width: ${({ isHome }) => (isHome ? '1340px' : '1220px')};
  margin-top: ${({ isHome }) => (isHome ? '-10px' : '0px')};

  ${({ theme }) => theme.mediaQueries.xs} {
    padding-top: 10px;
    padding-bottom: 24px;
    margin-top: ${({ isHome }) => (isHome ? '-20px' : '0px')};
    padding-left: 30px;
    padding-right: 30px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 12px;
    padding-bottom: 32px;
    margin-top: ${({ isHome }) => (isHome ? '-30px' : '0px')};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 12px;
    padding-bottom: 32px;
    margin-top: ${({ isHome }) => (isHome ? '-40px' : '0px')};
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 12px;
    padding-bottom: 32px;
    margin-top: ${({ isHome }) => (isHome ? '-50px' : '0px')};
  }
`

export default Page
