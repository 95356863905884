import { Nft } from './types'

const Nfts: Nft[] = [
  // {
  //   name: 'Bullish',
  //   description: 'Happy Niu Year! This bunny’s excited for the year of the bull (market!)',
  //   images: {
  //     lg: 'bullish-lg.png',
  //     md: 'bullish-md.png',
  //     sm: 'bullish-sm.png',
  //     ipfs: 'https://gateway.pinata.cloud/ipfs/QmNS1A5HsRW1JvFWtGkm4o9TgZVe2P7kA8TB4yxvS6A7ms/bullish.png',
  //   },
  //   video: {
  //     webm: 'https://gateway.pinata.cloud/ipfs/QmNS1A5HsRW1JvFWtGkm4o9TgZVe2P7kA8TB4yxvS6A7ms/bullish.webm',
  //     mp4: 'https://gateway.pinata.cloud/ipfs/QmNS1A5HsRW1JvFWtGkm4o9TgZVe2P7kA8TB4yxvS6A7ms/bullish.mp4',
  //   },
  //   sortOrder: 999,
  //   bunnyId: 11,
  // },
]

export default Nfts
